import axios from "axios";
import React, { useContext, createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { client } from "../utils/client";
const AppContext = createContext();

const AppProvider = ({ children }) => {
  // const baseUrl = "http://localhost:5000/api/";
  const baseUrl = "https://capitalflowexchange-api.onrender.com/api/";
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [toggleDeposit, setToggleDeposit] = useState(false);
  const [toggleWith, setToggleWith] = useState(false);
  const [firstWithdrawal, setFirstWithdrawal] = useState(false);
  const [proof, setProof] = useState(null);
  const [proofImage, setProofImage] = useState([]);
  const [depositHistory, setDepositHistory] = useState([]);
  const [depositLoading, setDepositLoading] = useState([]);
  const [checkWithdrawalStatus, setCheckWithdrawalStatus] = useState(false);
  const [steploading, setstepLoading] = useState(false);
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [bankDetails, setbankDetails] = useState(false);

  let moneyFormatter = new Intl.NumberFormat({
    style: "currency",
    currency: "USD",
  });
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const user = JSON.parse(sessionStorage.getItem("user")) || {};
  const [widthdrawalData, setWithdrawalData] = useState({});
  const _id = user?._id || "";
  const transferStep = user.transferStep || 0;
  const [address, setAddress] = useState([]);

  const validatePayments = (e, name) => {
    if (e.target.id === name) {
      setPaymentMethod(name);
    }
  };

  const getAllDeposits = (accessToken) => {
    setDepositLoading(true);
    axios
      .get(`${baseUrl}deposit/${_id}`, {
        headers: { token: accessToken },
      })
      .then((data) => {
        setDepositHistory(data.data.deposit);
        setDepositLoading(false);
        sessionStorage.setItem(
          "deposits",
          JSON.stringify(
            data.data.deposit
              .filter((deposits) => deposits.status === "true")
              .reduce((accumulator, { amount }) => accumulator + amount / 1, 0)
          )
        );
      })
      .catch((error) => {
        setDepositLoading(false);
      });
  };

  // Handler for toggling deposit modal
  const toggleStatus = () => {
    setToggleDeposit(!toggleDeposit);
    if (firstWithdrawal) {
      setFirstWithdrawal(false);
    }
  };

  // Handler for toggling first withdrawal modal
  const toggleFirstWithdrawal = () => setFirstWithdrawal(!firstWithdrawal);

  // Handler for checking withdrawal Status
  const toggleCheckWithdrawlStatus = () => {
    if (depositHistory.length <= 2 && transferStep === 2) {
      setCheckWithdrawalStatus(true);
    } else if (transferStep % 2 === 0) {
      setCheckWithdrawalStatus(true);
    } else {
      setCheckWithdrawalStatus(false);
    }
    getUser(accessToken, _id);
  };

  // Handler for toggling withdrawal modal
  const toggleWithdrawal = () => {
    setToggleWith(!toggleWith);
    toggleCheckWithdrawlStatus();
  };

  const updateOTP = () => {
    axios
      .put(
        `${baseUrl}users/user/${_id}`,
        {
          userOtp: 938756,
        },
        { headers: { token: accessToken } }
      )
      .then((response) => {})
      .catch((error) => {});
  };

  // handler for checking withdrawal steps and completing it
  const handleWithdrawalStep = (accessToken, widthdrawalData) => {
    setstepLoading(true);
    if (transferStep === 9) {
      axios
        .post(`${baseUrl}transfer`, widthdrawalData, {
          headers: { token: accessToken },
        })
        .then((data) => {
          toast.success("Withdrawal Successful");
          updateOTP();
          axios.put(
            `${baseUrl}users/user/${_id}`,
            {
              transferStep: transferStep + 1,
            },
            { headers: { token: accessToken } }
          );
        })
        .catch((error) => {
          toast.error("Withdrawal Failed Contact Customer Care", {
            theme: "dark",
          });
        });
    } else {
      axios
        .put(
          `${baseUrl}users/user/${_id}`,
          {
            transferStep: transferStep + 1,
          },
          { headers: { token: accessToken } }
        )
        .then((response) => {
          setstepLoading(false);
          updateOTP();
          toast.success("Thank you! Your Withdrawal has been processed");
        })
        .catch((error) => {
          setstepLoading(false);
        });
    }
  };

  // handler for getting user details
  const getUser = (token, id) => {
    setUserLoading(true);
    axios
      .get(`${baseUrl}users/${id}`, {
        headers: { token },
      })
      .then((data) => {
        if (data.status === 200) {
          setUserLoading(false);
          setUserDetails(data.data);
          sessionStorage.setItem("user", JSON.stringify(data.data));
        }
      })
      .catch((error) => {});
  };

  const getAllWithdrawals = (accessToken) => {
    setWithdrawLoading(true);

    axios
      .get(`${baseUrl}transfer/${_id}`, {
        headers: { token: accessToken },
      })
      .then((data) => {
        setWithdrawLoading(false);

        setWithdrawHistory(data.data.transfers);
        sessionStorage.setItem(
          "withdraw",
          JSON.stringify(data.data.totalAmountTransferred)
        );
      })
      .catch((error) => {
        setWithdrawLoading(false);
      });
  };

  const getAddresses = () => {
    client
      .fetch('*[_type == "addresses"]')
      .then((data) => {
        setAddress(data);
      })
      .catch((erro) => {});
  };

  useEffect(() => {
    getAddresses();
    client
      .fetch('*[_type == "bankdetails"]')
      .then((data) => {
        setbankDetails(data[0]);
      })
      .catch((erro) => {});
  }, []);

  return (
    <AppContext.Provider
      value={{
        paymentMethod,
        setPaymentMethod,
        validatePayments,
        address,
        baseUrl,
        proof,
        setProof,
        paymentType,
        setPaymentType,
        setProofImage,
        proofImage,
        getAllDeposits,
        depositHistory,
        depositLoading,
        toggleDeposit,
        toggleFirstWithdrawal,
        toggleStatus,
        toggleWith,
        toggleWithdrawal,
        setToggleWith,
        firstWithdrawal,
        setCheckWithdrawalStatus,
        checkWithdrawalStatus,
        handleWithdrawalStep,
        steploading,
        getUser,
        setWithdrawalData,
        widthdrawalData,
        moneyFormatter,
        getAllWithdrawals,
        withdrawHistory,
        withdrawLoading,
        bankDetails,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
