import React from "react";

const BasicInfo = ({ toggleModal }) => {
  const {
    firstName,
    lastName,
    email,
    dob,
    phone,
    country,
    middleName,
    maritalStatus,
    gender,
    city,
    address,
    age,
  } = JSON.parse(sessionStorage.getItem("user"));
  return (
    <>
      <div className="settings_tab settings_tab_2">
        <div>
          <div className="profile_circle">
            <h2>{firstName[0]}</h2>
            <h2>{lastName[0]}</h2>
          </div>
          <div>
            <h3>
              {firstName} {lastName}
            </h3>
            <p>{email}</p>
          </div>
        </div>
        <button className="submitted_btn">Submitted</button>
      </div>
      <div className="settings_tab personal_info_sect">
        <h3>Personal Information</h3>
        <div className="details_sect settings_btn_wrapper">
          <div>
            <h3 className="details_label">First Name</h3>
            <p>{firstName}</p>
          </div>
          <div>
            <h3 className="details_label">Last Name</h3>
            <p>{lastName}</p>
          </div>
          <div>
            <h3 className="details_label">Middle Name</h3>
            <p>{middleName}</p>
          </div>
          <div>
            <h3 className="details_label">Marital Status</h3>
            <p>{maritalStatus ? maritalStatus : "....."}</p>
          </div>
          <div>
            <h3 className="details_label">Gender</h3>
            <p>{gender ? gender : "....."}</p>
          </div>
          <div>
            <h3 className="details_label">Date Of Birth</h3>
            <p>{dob ? dob : "....."}</p>
          </div>
          <div>
            <h3 className="details_label">Email Address</h3>
            <p>{email}</p>
          </div>
          <div>
            <h3 className="details_label">Phone Number</h3>
            <p>{phone}</p>
          </div>
          <div>
            <h3 className="details_label">City</h3>
            <p>{city ? city : "....."}</p>
          </div>
          <div>
            <h3 className="details_label">Address</h3>
            <p>{address ? address : "....."}</p>
          </div>
          <div>
            <h3 className="details_label">Age</h3>
            <p>{age ? age : "....."}</p>
          </div>
          <div>
            <h3 className="details_label">Country</h3>
            <p>{country.name}</p>
          </div>
          <div>
            <h3 className="details_label">Currency</h3>
            <p>{country.symbol}</p>
          </div>
          <div></div>
          <div>
            <button onClick={toggleModal} className="submitted_btn">
              Update Details
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
