import styled from "styled-components";

export const MethodsWrapper = styled.div`
  padding: 30px;
`;

export const MethodOne = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
  justify-content: space-between;
`;
export const MethodsContainer = styled.div``;
export const TextContainer = styled.div``;
