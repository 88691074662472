import React from "react";
import { AsideWrapper } from "./Aside.styles";
import { Routes, Route } from "react-router-dom";
import { OverView, Settings, Transaction, Wallets } from "../../pages";

const Aside = () => {
  return (
    <AsideWrapper>
      <Routes>
        <Route path="/overview" element={<OverView />} />
        <Route path="/wallet" element={<Wallets />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/transaction" element={<Transaction />} />
      </Routes>
    </AsideWrapper>
  );
};

export default Aside;
