import React from "react";
import "./Exchange.css";

// Components
import { Button, Footer, Header } from "../../components";

// Images
import heroImage from "../../assets/portfolio.webp";
import buying from "../../assets/buying.webp";
import homepage from "../../assets/homepage2.webp";
import streaming from "../../assets/streaming.webp";
import myportfolio from "../../assets/myportfolio.webp";
import best from "../../assets/bestAward.webp";
import marketchoice from "../../assets/marketchoice.webp";
import exchange from "../../assets/exchange.webp";
import ascent from "../../assets/ascent.webp";
import company from "../../assets/company.webp";
import transaction from "../../assets/transaction.svg";
import enable from "../../assets/enable.svg";
import sync from "../../assets/sync.svg";
import support from "../../assets/chat.svg";

// Icons

const Exchange = () => {
  const name = "Capital Flow Exchange";

  return (
    <section className="exchange_page">
      <Header />
      <div className="exchange_hero_sect">
        <div className="exchange_hero_sect_text_sect">
          <h1 className="header_text">{name}</h1>
          <h3 className="text_text">
            Our exchange makes it simple to research the crypto market, buy
            bitcoin and other cryptos, and build a portfolio for the future of
            money.
          </h3>
          <Button title={"Sign Up"} />
        </div>
        <div className="exchange_hero_sect_image_sect">
          <img src={heroImage} alt="" />
        </div>
      </div>
      <div className="exchange_hero_sect reversed_exchange_hero_sect">
        <div className="exchange_hero_sect_text_sect">
          <h1 className="home_header_text">Streamlined trading view</h1>
          <h3 className="text_text">
            Access all the tools you need to understand the crypto market and
            start investing – all through one clear, attractive interface.
          </h3>
        </div>
        <div className="exchange_hero_sect_image_sect">
          <img src={streaming} alt="" />
        </div>
      </div>
      <div className="exchange_hero_sect">
        <div className="exchange_hero_sect_text_sect">
          <h1 className="home_header_text">Buying and selling, made simple</h1>
          <h3 className="text_text">
            Trade assets, manage your holdings, and set recurring buys on the
            crypto industry’s most secure, trusted platform.
          </h3>
        </div>
        <div className="exchange_hero_sect_image_sect">
          <img src={buying} alt="" />
        </div>
      </div>
      <div className="exchange_hero_sect reversed_exchange_hero_sect">
        <div className="exchange_hero_sect_text_sect">
          <h1 className="header_text">My Portfolio</h1>
          <h3 className="text_text">
            Manage your account at-a-glance. View your account balance, 24-hour
            change, and percent change.
          </h3>
        </div>
        <div className="exchange_hero_sect_image_sect">
          <img src={myportfolio} alt="" />
        </div>
      </div>
      <div className="exchange_hero_sect">
        <div className="exchange_hero_sect_text_sect">
          <h1 className="home_header_text">Homepage</h1>
          <h3 className="text_text">
            The {name} account Homepage provides you with a snapshot of your
            account and includes watchlists, a new top movers section, and
            educational content.
          </h3>
        </div>
        <div className="exchange_hero_sect_image_sect">
          <img src={homepage} alt="" />
        </div>
      </div>
      <div className="exchange_award_sect">
        <div>
          <img src={best} alt="" />
          <div>
            <p>Nerdwallet's Best-Of Awards 2022</p>
            <h5 className="text_text">Best Crypto Exchange for Beginners</h5>
          </div>
        </div>
        <div>
          <img src={company} alt="" />
          <div>
            <p>SOC 1 Type 2 and SOC 2 Type 2</p>
            <h5 className="text_text">1st exchange to complete these exams</h5>
          </div>
        </div>
        <div>
          <img src={exchange} alt="" />
          <div>
            <p>2020 CryptoCompare Report</p>
            <h3 className="text_text">#1 Cryptocurrency Exchange</h3>
          </div>
        </div>
        <div>
          <img src={ascent} alt="" />
          <div>
            <p>The Ascent 2022 Best Crypto Exchanges</p>
            <h5 className="text-_text">Best Crypto Exchange Overall</h5>
          </div>
        </div>
        <div>
          <img src={marketchoice} alt="" />
          <div>
            <p>2019 Markets Choice Awards</p>
            <h5 className="text_text">Best Crypto Exchange Overall</h5>
          </div>
        </div>
      </div>
      <div className="exchange_last_sect">
        <h2 className="home_header_text">Product specs</h2>
        <div className="exchange_last_sect_flex">
          <div>
            <img src={sync} alt="" />
            <p className="text_text">
              Set recurring buys and dynamic price alerts
            </p>
          </div>
          <div>
            <img src={support} alt="" />
            <p className="text_text">Industry leading customer support</p>
          </div>
          <div>
            <img src={enable} alt="" />
            <p className="text_text">
              Enable crypto withdrawals to go only to approved addresses
            </p>
          </div>
          <div>
            <img src={transaction} alt="" />
            <p className="text_text">
              Transaction histories and downloadable account statements
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Exchange;
