import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import { CiWallet } from "react-icons/ci";
import { GrTransaction } from "react-icons/gr";
import { AiOutlineHome } from "react-icons/ai";

import "./style.css";

// Styles
import {
  NavbarWrapper,
  NavbarLogoSection,
  Image,
  NavListWrapper,
  NavListItemsContainer,
} from "./Navbar.styles";
import { Text } from "../general.styles";

// Images
import logo from "../../../../assets/white.png";

// Constants
import { NAV_LIST } from "../../../../constants";

const Navbar = () => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(pathname);

  const setActive = (path) => {
    setActiveTab(path);
  };

  useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  return (
    <>
      <NavbarWrapper>
        <NavbarLogoSection>
          <Image src={logo} width={135} alt="nav logo" />
        </NavbarLogoSection>
        <NavListWrapper>
          {NAV_LIST.map((list, index) => {
            const { sect, lists } = list;
            return (
              <Fragment key={index}>
                <Text
                  color={"rgb(165, 177, 189)"}
                  fontWeight={400}
                  size={"17px"}
                  marginbottom={"30px"}
                  texttransform={"capitalize"}
                >
                  {sect}
                </Text>
                {lists.map((item, index) => (
                  <NavListItemsContainer
                    to={item.path}
                    activetab={activeTab === item.path}
                    key={index}
                    onClick={() => {
                      setActive(item.path);
                      if (item.name === "logout") {
                        sessionStorage.removeItem("accessToken");
                      }
                    }}
                  >
                    <>
                      {activeTab === item.path ? item.showIcon : item.hideIcon}
                    </>
                    <Text
                      activetab={activeTab === item.path}
                      color={"rgb(165, 177, 189)"}
                      fontWeight={500}
                      size={"17px"}
                      texttransform={"capitalize"}
                    >
                      {item.name === "wallet" ? "Wallet/Portfolio" : item.name}
                    </Text>
                  </NavListItemsContainer>
                ))}
              </Fragment>
            );
          })}
        </NavListWrapper>
      </NavbarWrapper>
      <section className="bottom_nav">
        <section className="radius_sect">
          <Link to={"/overview"}>
            <AiOutlineHome color="rgb(167, 177, 189)" size={28} /> <p>Home</p>
          </Link>
          <Link to={"/wallet"}>
            <CiWallet color="rgb(167, 177, 189)" size={28} /> <p>Wallet</p>
          </Link>
          <Link to={"/transaction"}>
            <GrTransaction color="rgb(167, 177, 189)" size={28} />{" "}
            <p>History</p>
          </Link>
          <Link to={"/settings"}>
            <FaRegUserCircle color="rgb(167, 177, 189)" size={28} />{" "}
            <p>Profile</p>
          </Link>
        </section>
      </section>
    </>
  );
};

export default Navbar;
