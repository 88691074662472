import React, { useEffect, useState } from "react";
import {
  AdvancedRealTimeChart,
  CryptoCurrencyMarket,
} from "react-ts-tradingview-widgets";

// Components
import { DashboardHeader } from "../../components";

// Styles
import {
  OverViewSectOne,
  OverViewSectOneAside,
  OverViewSectOneView,
  OverViewSectOneViewSectionOne,
  OverViewSectTwo,
  OverViewWrapper,
  OverViewSectOneViewHeader,
  OverViewSectOneViewHeaderBtnSect,
  Btn,
  NotificationModal,
  OverViewSectOneViewFooter,
  QuickItem,
} from "./OverView.styles";
import { Image } from "../../components/Navbar/Navbar.styles";
import { Text } from "../../components/general.styles";

// Images
import bgOne from "../../../../assets/need-help-bg.071e9de701e0d1900fdd.png";
import bgTwo from "../../../../assets/gradient-bg.28ab131e7b3bc109c5e1.png";

// Icons
import { IoDownloadOutline } from "react-icons/io5";
import { optionsList } from "../../../../constants";
import { useGlobalContext } from "../../../../context/context";
import ModalbileHeader from "../../components/MobileHeader";
import Feature from "../Settings/Feature";

const OverView = () => {
  const [buy, setBuy] = useState(false);
  const [sell, setsell] = useState(false);
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const { country, _id, profit, bonus, lastName, firstName } = JSON.parse(
    sessionStorage.getItem("user")
  );
  const depositTotal = JSON.parse(sessionStorage.getItem("deposits"));
  const withdrawTotal = JSON.parse(sessionStorage.getItem("withdraw"));
  const totalAmount = profit + depositTotal + bonus - withdrawTotal;
  const {
    toggleStatus,
    toggleWithdrawal,
    getAllDeposits,
    getUser,
    moneyFormatter,
    getAllWithdrawals,
  } = useGlobalContext();

  const toggleBuy = () => setBuy(!buy);
  const toggleSell = () => setsell(!sell);

  useEffect(() => {
    getAllDeposits(accessToken);
    getUser(accessToken, _id);
    getAllWithdrawals(accessToken);
  }, []);

  return (
    <>
      <OverViewWrapper>
        <DashboardHeader pathname={"/"} />
        <OverViewSectOne>
          <OverViewSectOneView>
            <OverViewSectOneViewHeader paddingy="80px">
              <OverViewSectOneViewSectionOne>
                <Image
                  radius="50px"
                  height={70}
                  width={70}
                  src={country?.flag}
                  alt="currency logo"
                />
                <Text color="white" size={"35px"} fontWeight={600}>
                  {country?.symbol}
                  {moneyFormatter.format(totalAmount)}
                </Text>
              </OverViewSectOneViewSectionOne>
              <OverViewSectOneViewHeaderBtnSect>
                <Btn
                  onClick={toggleStatus}
                  bg={"rgb(37, 43, 49)"}
                  fontWeight={500}
                >
                  <IoDownloadOutline />
                  Deposit
                </Btn>
                <Btn
                  onClick={toggleWithdrawal}
                  bg={"rgb(37, 43, 49)"}
                  fontWeight={500}
                >
                  <IoDownloadOutline />
                  Withdraw
                </Btn>
              </OverViewSectOneViewHeaderBtnSect>
            </OverViewSectOneViewHeader>
            <OverViewSectOneViewFooter>
              {optionsList.map((item, index) => (
                <QuickItem key={index}>
                  <Image marginbottom={"25px"} src={item.src} />
                  <Text
                    color="white"
                    size={"18px"}
                    fontWeight={400}
                    texttransform={
                      item.text === "nft" ? "uppercase" : "capitalize"
                    }
                  >
                    {item.text}
                  </Text>
                </QuickItem>
              ))}
            </OverViewSectOneViewFooter>
          </OverViewSectOneView>
          <OverViewSectOneAside margintop="15px">
            <NotificationModal bg={bgOne}>
              <Text marginbottom={"10px"} size={"20px"} fontWeight={600}>
                Read latest Happenings
              </Text>
              <Text marginbottom={"20px"} size={"16px"} fontWeight={400}>
                Read the latest gist on crypto and blockchain on Roqqu Hub
              </Text>
              <Btn fontWeight={600} bg={"rgb(50, 56, 63)"}>
                Coming Soon
              </Btn>
            </NotificationModal>
            <NotificationModal bg={bgTwo}>
              <Text marginbottom={"10px"} size={"20px"} fontWeight={600}>
                Refer and Earn
              </Text>
              <Text marginbottom={"20px"} size={"16px"} fontWeight={400}>
                Refer your friends and earn 0.5% on all their transactions when
                they sign up
              </Text>
              <Btn fontWeight={600} bg={"black"}>
                Coming Soon
              </Btn>
            </NotificationModal>
          </OverViewSectOneAside>
        </OverViewSectOne>
        <OverViewSectTwo>
          <AdvancedRealTimeChart theme="dark" width={"100%"} />
          <CryptoCurrencyMarket colorTheme="dark" width={"100%"} />
        </OverViewSectTwo>
      </OverViewWrapper>
      <ModalbileHeader
        total={totalAmount}
        firstName={firstName}
        lastName={lastName}
        toggleBuy={toggleBuy}
        toggleSell={toggleSell}
      />
      {sell && <Feature toggleModal={toggleSell} modal={sell} />}
      {buy && <Feature toggleModal={toggleBuy} modal={buy} />}
    </>
  );
};

export default OverView;
