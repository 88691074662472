import React from "react";
import "./Home.css";

// Components
import { Button, Footer, Header } from "../../components";

// Icons

import { BsArrowRight } from "react-icons/bs";

// Images
import trading from "../../assets/trading.avif";
import tablet from "../../assets/tablet.avif";
import atm from "../../assets/atm.avif";
import sticks from "../../assets/sticks.avif";
import { AiOutlinePlus } from "react-icons/ai";
import code from "../../assets/code.png";
import authenticate from "../../assets/two-factor-authentication__1_.png";
import address from "../../assets/Approved_addresses__1_.webp";
import portfolio from "../../assets/homepage-retail-portfolio.avif";
import frame from "../../assets/YourPortfolio_EmptyFrame__1_.avif";

const Home = () => {
  const name = "Capitalflowexchange";

  return (
    <section className="home_sect">
      <Header />
      <div className="home_hero_sect">
        <div className="home_hero_trading_sect">
          <img src={trading} alt="" />
        </div>
        <div className="home_hero_sect_one">
          <h1 className="header_text">Turn your money into crypto assets</h1>
          <p className="text_text">
            The secure way to buy, sell, store, and convert crypto. Millions use
            <span> </span> {name} to diversify their portfolios.
          </p>
          <Button title={"Get Started"} />
        </div>
        <div className="home_hero_tablet_sect">
          <img src={tablet} alt="" />
        </div>
      </div>
      <h1 className="header_text bold_home_text">Crypto, covered.</h1>
      <h2 className="text_2">
        We’re crypto-obsessed and compliance-focused, making it safe and easy to
        buy and sell crypto.
      </h2>
      <div className="home_referral_sect">
        <div className="referral_item_one">
          <h2 className="home_header_text align_left">
            Can your friends make you $50k?
          </h2>
        </div>
        <div className="referral_item_two">
          <h2 className="home_header_text">Get free crypto rewards</h2>
          <div className="arrow_btn">
            <BsArrowRight size={18} />
          </div>
        </div>
      </div>
      <div className="mobile_referral_sect home_referral_sect">
        <div className="referral_item_one">
          <h2 className="header_text align_left">Trade Crypto in Seconds</h2>
        </div>
        <video
          src={
            "https://videos.ctfassets.net/jg6lo9a2ukvr/3sydoZJEelWNKjvKrUpmJW/4c85d0c34ac8d93f73224b77bccad173/Update_ATPhone_Compressed_1___1_.mp4"
          }
          typ="video/mp4"
          loop
          controls={false}
          muted
          autoPlay
          width="80%"
          height="100%"
          className="video"
        ></video>
        <div className="referral_item_two">
          <h2 className="home_header_text">Mobile App</h2>
          <div className="arrow_btn">
            <BsArrowRight size={18} />
          </div>
        </div>
      </div>
      <div className="home_sect_three">
        <div className="home_sect_three_item_one">
          <h2 className="home_header_text align_left">
            Trade crypto in seconds
          </h2>
        </div>

        <video
          src={
            "https://videos.ctfassets.net/jg6lo9a2ukvr/3sydoZJEelWNKjvKrUpmJW/4c85d0c34ac8d93f73224b77bccad173/Update_ATPhone_Compressed_1___1_.mp4"
          }
          typ="video/mp4"
          loop
          controls={false}
          muted
          autoPlay
          width="30%"
          height="100%"
        ></video>

        <div className="home_sect_three_item_three"></div>
      </div>
      <div className="unlock_home_sect home_referral_sect">
        <div className="referral_item_one">
          <h2 className="home_header_text align_left">
            Unlock low-fee advanced trading
          </h2>
        </div>
        <div className="referral_item_two">
          <h2 className="home_header_text">ActiveTrader</h2>
          <div className="arrow_btn">
            <BsArrowRight size={18} />
          </div>
        </div>
      </div>
      <div className="home_sect_four">
        <div className="home_sect_four_item_one">
          <img src={atm} alt="" />
          <h2 className="header_text align_left">Invest when you spend</h2>
          <p>
            Engaging with crypto involves risks, including the risk of your
            crypto rewards declining in value over time. Past performance no
            guarantee of future results. A {name} Mastercard®. Issued by
            WebBank. Terms apply.
          </p>
          <div className="referral_item_two">
            <h2 className="text_2">Credit Card</h2>
            <div className="arrow_btn">
              <BsArrowRight size={18} />
            </div>
          </div>
        </div>
        <div className="home_sect_four_item_two">
          <h2 className="header_text align_left">Get rewards on your crypto</h2>
          <img src={sticks} alt="" />
          <div className="referral_item_two">
            <h2 className="text_2">Staking</h2>
            <div className="arrow_btn">
              <BsArrowRight size={18} />
            </div>
          </div>
        </div>
        <div className="home_sect_four_item_three">
          <h2 className="header_text align_left">Buy and Sell NFTs</h2>
          <div className="referral_item_two">
            <h2 className="text_2">Nifty Gateway</h2>
            <div className="arrow_btn">
              <BsArrowRight size={18} />
            </div>
          </div>
        </div>
      </div>
      <div className="home_sect_five home_referral_sect">
        <div className="referral_item_one">
          <h2 className="header_text align_left">
            Elevate your trading strategies with derivatives contracts on
            ActiveTrader
          </h2>
        </div>
        <div className="referral_item_two">
          <h2 className="home_header_text">Derivatives</h2>
          <div className="arrow_btn">
            <BsArrowRight size={18} />
          </div>
        </div>
      </div>
      <h1 className="header_text bold_home_text">We’re here. For good.</h1>
      <h2 className="text_2">
        {name} is a full-reserve and highly-regulated cryptocurrency exchange
        and custodian.
      </h2>
      <div className="home_sect_six">
        <div className="home_sect_six_item_1">
          <h3 className="text_2">Based in NYC since 2014</h3>
          <h2 className="home_header_text">Available in 70+ countries.</h2>
          <div className="referral_item_two">
            <h2 className="text_2">{``}</h2>
            <div className="arrow_btn">
              <AiOutlinePlus size={18} color="black" />
            </div>
          </div>
        </div>
        <div className="home_sect_six_item_2">
          <h3 className="text_2">We Are</h3>
          <h1 className="header_text">Certified. Regulated. Licensed.</h1>
          <h2 className="text_2">
            From day one, {name} has prioritized the security of your assets. We
            never have and never will compromise on that.
          </h2>
          <div className="referral_item_two">
            <h2 className="text_2">{``}</h2>
            <div className="arrow_btn">
              <AiOutlinePlus size={18} color="white" />
            </div>
          </div>
        </div>
        <div className="home_sect_six_item_3">
          <h3 className="text_2">Our mantra</h3>
          <h2 className="text_2">Asking permission, not forgiveness.</h2>
          <div className="referral_item_two">
            <h2 className="text_2">{``}</h2>
            <div className="arrow_btn">
              <AiOutlinePlus size={18} color="#00b0cb" />
            </div>
          </div>
        </div>
        <div className="home_sect_six_item_4">
          <h3 className="text_2">The Ascent 2022 Best Crypto Exchanges</h3>
          <h1 className="header_text">Best Crypto Exchange Overall</h1>
          <div className="referral_item_two">
            <h2 className="text_2">{``}</h2>
            <div className="arrow_btn">
              <AiOutlinePlus size={18} color="#bebaa2" />
            </div>
          </div>
        </div>
        <div className="home_sect_six_item_5">
          <h3 className="text_2">We crypto</h3>
          <h2 className="text_2">
            Crypto isn’t just a technology, it’s a movement. And it's just
            getting started.
          </h2>
          <div className="referral_item_two">
            <h2 className="text_2">{``}</h2>
            <div className="arrow_btn">
              <AiOutlinePlus size={18} color="#fd6d6d" />
            </div>
          </div>
        </div>
      </div>
      <h1 className="header_text bold_home_text">
        A safe place for your crypto
      </h1>
      <h2 className="text_2">
        We’re focused on providing innovative security solutions to protect your
        assets.
      </h2>
      <p className="text_text">Learn more about our security measures</p>
      <div className="home_sect_sev">
        <div>
          <img src={code} alt="" />
          <div className="referral_item_two">
            <h2 className="text_2">Hardware Security keys</h2>
            <div className="arrow_btn">
              <AiOutlinePlus size={18} color="white" />
            </div>
          </div>
        </div>
        <div>
          <img src={authenticate} alt="" />
          <div className="referral_item_two">
            <h2 className="text_2">Two-Factor Authentication</h2>
            <div className="arrow_btn">
              <AiOutlinePlus size={18} color="white" />
            </div>
          </div>
        </div>
        <div>
          <img src={address} alt="" />
          <div className="referral_item_two">
            <h2 className="text_2">Approved Address</h2>
            <div className="arrow_btn">
              <AiOutlinePlus size={18} color="white" />
            </div>
          </div>
        </div>
      </div>
      <h1 className="header_text bold_home_text">Your portfolio. Your way.</h1>
      <h2 className="text_2">See which interface is right for you.</h2>
      <div className="home_sect_eight">
        <img className="frame" src={frame} alt="" />
        <img className="portfolio" src={portfolio} alt="" />
      </div>
      <h1 className="header_text bold_home_text">
        When crypto is your business
      </h1>
      <h2 className="text_2">
        {name} works with the best. We’re partnered with leading institutions
        both large and small.
      </h2>
      <div className="home_sect_nine home_sect_five home_referral_sect">
        <div className="referral_item_one">
          <h2 className="home_header_text">
            Store and manage digital assets on behalf of clients
          </h2>
        </div>
        <div className="referral_item_two">
          <h2 id="shorten_text" className="home_header_text">
            Capital Flow Exchange Institutional
          </h2>
          <div className="arrow_btn">
            <BsArrowRight size={18} />
          </div>
        </div>
      </div>
      <h1 className="header_text bold_home_text">
        Over $200 billion USD in volume traded
      </h1>
      <h2 className="text_2">Join the movement.</h2>
      <div className="home_direct_btn_container">
        <Button title={"Start Trading Today"} />
      </div>
      <Footer />
    </section>
  );
};

export default Home;
