import React from "react";

// Styles
import { DepositForm, Input } from "../DepositModal/DepositModal.styles";
import { Text } from "../../general.styles";

const WithdrawalSecondModal = ({ register }) => {
  return (
    <DepositForm>
      <Text
        marginbottom="10px"
        color="rgb(165, 177, 189)"
        size="16px"
        fontWeight={400}
      >
        Bank Name
      </Text>
      <Input placeholder="Enter Bank Name" {...register("bankName")} />
      <Text
        marginbottom="10px"
        color="rgb(165, 177, 189)"
        size="16px"
        fontWeight={400}
      >
        Account Name
      </Text>
      <Input placeholder="Enter Account Name" {...register("accountName")} />
      <Text
        marginbottom="10px"
        color="rgb(165, 177, 189)"
        size="16px"
        fontWeight={400}
      >
        Account Number
      </Text>
      <Input
        placeholder="Enter Account Number"
        {...register("accountNumber")}
      />
      <Text
        marginbottom="10px"
        color="rgb(165, 177, 189)"
        size="16px"
        fontWeight={400}
      >
        Account Type
      </Text>
      <Input placeholder="Enter Account Type" {...register("accountType")} />
    </DepositForm>
  );
};

export default WithdrawalSecondModal;
