import React from "react";

// Styles
import {
  DashboardHeaderWrapper,
  DashboardHeaderItemTwo,
  DashboardHeaderItemOne,
  DashboardProfileContainer,
} from "./DashboardHeader.styles";
import { Text } from "../general.styles";

// Icons

import { IoIosNotificationsOutline, IoIosArrowBack } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import { CiGlobe, CiLogin } from "react-icons/ci";
import { checPath } from "../../../../helper-methods";
import { useNavigate } from "react-router-dom";

const DashboardHeader = ({ pathname }) => {
  const { firstName, lastName } = JSON.parse(sessionStorage.getItem("user"));
  const navigate = useNavigate();
  const navigateToSettings = () => {
    navigate("/settings");
  };
  const logOut = () => {
    sessionStorage.removeItem("accessToken");
    navigate("/auth/login");
  };
  return (
    <DashboardHeaderWrapper>
      <DashboardHeaderItemOne>
        {pathname === "/" ? (
          ""
        ) : (
          <IoIosArrowBack color="rgb(160, 210, 254)" size={28} />
        )}
        <Text color="rgb(165, 177, 189)" size={"18px"} fontWeight={500}>
          {checPath(pathname)}
        </Text>
      </DashboardHeaderItemOne>
      <DashboardHeaderItemTwo>
        <DashboardProfileContainer onClick={navigateToSettings}>
          <Text
            color="white"
            size={"13px"}
            fontWeight={300}
            texttransform="capitalize"
          >
            {firstName} {lastName}
          </Text>
          <MdKeyboardArrowRight color="white" size={20} />
        </DashboardProfileContainer>
        <IoIosNotificationsOutline color="rgb(165, 177, 189)" size={28} />
        <CiGlobe color="rgb(165, 177, 189)" size={28} />
        <CiLogin color="rgb(165, 177, 189)" size={28} onClick={logOut} />
      </DashboardHeaderItemTwo>
    </DashboardHeaderWrapper>
  );
};

export default DashboardHeader;
