import React, { useEffect } from "react";
import "./styles.css";

// Components
import { DashboardHeader, MiniHeader, Price } from "../../components";

// Styles
import {
  ProfitWrapper,
  WalletsWrapper,
  MorqueeSect,
  MorqueeSectWrapper,
  CountryCurrencyWrapper,
  CountryCurrencyImageWrapper,
} from "./Wallets.styles";
import {
  OverViewSectOne,
  OverViewSectOneAside,
  OverViewSectOneView,
  OverViewSectOneViewHeader,
  OverViewSectOneViewSectionOne,
  Btn,
} from "../OverView/OverView.styles";
import { Image } from "../../components/Navbar/Navbar.styles";
import { Text } from "../../components/general.styles";

// Images
import deposit from "../../../../assets/wallet-deposit.svg";
import Withdraw from "../../../../assets/wallet-withdraw.svg";
import transfer from "../../../../assets/wallet-transfer.svg";
import currency from "../../../../assets/currency.svg";
import { useGlobalContext } from "../../../../context/context";

const Wallets = () => {
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const { country, _id, profit, status, bonus } = JSON.parse(
    sessionStorage.getItem("user")
  );
  const depositTotal = JSON.parse(sessionStorage.getItem("deposits"));

  const totalBalance = profit + bonus + depositTotal;

  const {
    toggleStatus,
    toggleWithdrawal,
    getAllDeposits,
    getUser,
    moneyFormatter,
    getAllWithdrawals,
  } = useGlobalContext();

  useEffect(() => {
    getAllDeposits(accessToken);
    getUser(accessToken, _id);
    getAllWithdrawals(accessToken);
  }, []);

  return (
    <WalletsWrapper>
      <DashboardHeader pathname={"Wallet"} />
      <OverViewSectOne>
        <OverViewSectOneView width={"30%"}>
          <OverViewSectOneViewHeader paddingy={"30px"}>
            <ProfitWrapper>
              <Text
                color="rgb(165, 177, 189)"
                size="30px"
                fontWeight="500"
                marginbottom="15px"
              >
                Total Deposit
              </Text>
              <OverViewSectOneViewSectionOne>
                <Image
                  height={70}
                  width={70}
                  radius="50px"
                  src={country?.flag}
                  alt="currency logo"
                />
                <Text color="white" size={"35px"} fontWeight={600}>
                  {country?.symbol}
                  {moneyFormatter.format(depositTotal)}
                </Text>
              </OverViewSectOneViewSectionOne>
              <Text
                color="rgb(167, 177, 188)"
                fontWeight={500}
                size={"16px"}
                margintop="20px"
              >
                This is the total value of your deposits
              </Text>
            </ProfitWrapper>
          </OverViewSectOneViewHeader>
        </OverViewSectOneView>
        <OverViewSectOneAside width={"70%"}>
          <OverViewSectOneViewHeader paddingy="30px">
            <ProfitWrapper>
              <Text
                color="rgb(165, 177, 189)"
                size="30px"
                fontWeight="500"
                marginbottom="15px"
              >
                Total Profit
              </Text>
              <OverViewSectOneViewSectionOne>
                <Image
                  height={70}
                  width={70}
                  radius="50px"
                  src={country.flag}
                  alt="currency logo"
                />
                <Text color="white" size={"35px"} fontWeight={600}>
                  {country.symbol}
                  {moneyFormatter.format(profit)}
                </Text>
              </OverViewSectOneViewSectionOne>
              <Text
                color="rgb(167, 177, 188)"
                fontWeight={500}
                size={"16px"}
                margintop="20px"
              >
                This is the total growth value of your crypto portfolio in the
                last 24hrs
              </Text>
              {totalBalance > 0 && (
                <div className="profit_pending_wrapper">
                  <p className="profit_pending_wrapper_text">
                    {status === "false"
                      ? "Withdrawal Inactive"
                      : "Withdrawal Active"}
                  </p>
                </div>
              )}
            </ProfitWrapper>
          </OverViewSectOneViewHeader>
        </OverViewSectOneAside>
      </OverViewSectOne>
      <MorqueeSectWrapper>
        <MorqueeSect>
          <Btn
            onClick={toggleStatus}
            height={"65px"}
            width="250px"
            bg={"rgb(32, 37, 43)"}
          >
            <Image src={deposit} height={30} width={30} /> Deposit
          </Btn>
          <Btn
            onClick={toggleWithdrawal}
            height={"65px"}
            width="250px"
            bg={"rgb(32, 37, 43)"}
          >
            <Image src={Withdraw} height={30} width={30} /> Withdraw
          </Btn>
          <Btn
            onClick={toggleWithdrawal}
            height={"65px"}
            width="250px"
            bg={"rgb(32, 37, 43)"}
          >
            <Image src={transfer} height={30} width={30} /> Transfer
          </Btn>
          <CountryCurrencyWrapper>
            <Btn
              onClick={toggleStatus}
              height={"65px"}
              width="500px"
              bg={"rgb(32, 37, 43)"}
            >
              <CountryCurrencyImageWrapper>
                <Text size="20px">{country.symbol}</Text>
              </CountryCurrencyImageWrapper>
              <Image src={currency} height={80} width={80} /> Fund and Transact
              with Local Currency
            </Btn>
          </CountryCurrencyWrapper>
        </MorqueeSect>
      </MorqueeSectWrapper>
      <MiniHeader />
      <div className="wallet_total_balance_sect">
        <div>
          <h3>Total Deposit</h3>
          <p>
            {country.symbol}
            {moneyFormatter.format(depositTotal)}
          </p>
          <p className="indi">This is the total value of your deposits</p>
        </div>
        <div>
          <h3>Profit</h3>
          <p>
            {country.symbol}
            {moneyFormatter.format(profit)}
          </p>
          <p className="indi">
            This is the total growth value of your crypto portfolio in the last
            24hrs
          </p>
        </div>
        {totalBalance > 0 && (
          <div className="profit_pending_wrapper" onClick={toggleWithdrawal}>
            <p className="profit_pending_wrapper_text">
              {status === "false" ? "Withdrawal Inactive" : "Withdrawal Active"}
            </p>
          </div>
        )}
      </div>
      <Price />
    </WalletsWrapper>
  );
};

export default Wallets;
