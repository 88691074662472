import styled from "styled-components";

export const WalletsWrapper = styled.section``;

export const ProfitWrapper = styled.div``;
export const MorqueeSectWrapper = styled.div`
  overflow-x: scroll;
`;

export const MorqueeSect = styled.div`
  display: flex;
  align-items: center;
  padding: 30px;
  gap: 25px;
  border-bottom: 1px solid #535c6480;
  width: 1350px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const CountryCurrencyWrapper = styled.div`
  position: relative;
`;
export const CountryCurrencyImageWrapper = styled.div`
  position: absolute;
  inset: 0;
  top: 18px;
  width: 40px;
  left: 75px;
`;

export const UserPortfolioWrapper = styled.div``;
