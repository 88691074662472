import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  About,
  Exchange,
  ForgetPassword,
  Home,
  Login,
  NewPassword,
  Partners,
  Prices,
  Privacy,
  Register,
  Terms,
  UserDashbord,
} from "./pages";
import { useEffect, useState } from "react";
import { PrivateRoute } from "./utils/PrivateRoutes";
import { useGlobalContext } from "./context/context";
import Withdrawal from "./pages/UserDashboard/components/Modals/WithdrawalModal/WithdrawalModal";
import FirstWithdrawal from "./pages/UserDashboard/components/Modals/FirstModal/FirstWithdrawalStep";
import { DepositModal } from "./pages/UserDashboard/components/Modals";
import CheckWithdrawal from "./pages/UserDashboard/components/Modals/WithdrawalModal/CheckWithdrawal";

function App() {
  const { pathname } = useLocation();
  const {
    toggleDeposit,
    toggleFirstWithdrawal,
    toggleStatus,
    toggleWithdrawal,
    toggleWith,
    setToggleWith,
    firstWithdrawal,
    checkWithdrawalStatus,
    setCheckWithdrawalStatus,
  } = useGlobalContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<UserDashbord />} path="*" />
        </Route>
        <Route element={<Home />} path="/" />
        <Route element={<About />} path="/about-us" />
        <Route element={<Exchange />} path="/exchange" />
        <Route element={<Privacy />} path="/privacy" />
        <Route element={<Terms />} path="/terms" />
        <Route element={<Register />} path="/auth/register" />
        <Route element={<Login />} path="/auth/login" />
        <Route element={<ForgetPassword />} path="/auth/forget" />
        <Route element={<NewPassword />} path="/auth/reset-password/:id" />
        <Route element={<Prices />} path="/prices" />
        <Route element={<Partners />} path="/partners" />
      </Routes>

      <DepositModal toggleStatus={toggleStatus} depositStatus={toggleDeposit} />
      <Withdrawal
        toggleStatus={toggleWithdrawal}
        withdrawalStatus={toggleWith}
        setWithdrawalStatus={setToggleWith}
      />
      <FirstWithdrawal
        toggleStatus={toggleFirstWithdrawal}
        firstWithdrawalStatus={firstWithdrawal}
        toggleDeposit={toggleStatus}
      />
      <CheckWithdrawal
        checkWithdrawalStatus={checkWithdrawalStatus}
        setCheckWithdrawalStatus={setCheckWithdrawalStatus}
      />
      <ToastContainer theme="dark" />
    </div>
  );
}

export default App;
