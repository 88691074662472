import React, { useState } from "react";
import "../../components/Modals/FirstModal/styles.css";
import { useGlobalContext } from "../../../../context/context";
import {
  DepositModalWrappper,
  Input,
} from "../../components/Modals/DepositModal/DepositModal.styles";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const UpdateDetailsModal = ({ toggleModal, modal, setTab }) => {
  const [loading, setLoading] = useState(false);
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const {
    firstName,
    dob,
    maritalstatus,
    gender,
    city,
    address,
    lastName,
    middleName,
    age,
    _id,
  } = JSON.parse(sessionStorage.getItem("user")) || {};
  const { register, handleSubmit } = useForm();
  const { getUser, baseUrl } = useGlobalContext();

  const handleUpdate = async (data) => {
    setLoading(true);

    data.dob = data.dob ? data.dob : dob;
    data.city = data.city ? data.city : city;
    data.address = data.address ? data.address : address;
    data.age = data.age ? data.age : age;
    data.gender = data.gender ? data.gender : gender;
    data.firstName = data.firstName ? data.firstName : firstName;
    data.lastName = data.lastName ? data.lastName : lastName;
    data.middleName = data.middleName ? data.middleName : middleName;
    data.maritalstatus = data.maritalstatus
      ? data.maritalstatus
      : maritalstatus;

    try {
      const updateUser = await axios.put(`${baseUrl}users/${_id}`, data, {
        headers: { token: accessToken },
      });
      if (updateUser.status === 200) {
        setLoading(false);
        getUser(accessToken, _id);
        toast.success("Details Updated");
        setTimeout(() => {
          setTab(1);
          toggleModal();
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <DepositModalWrappper width={modal}>
      <div className="first_withdrawal_modal_wrapper">
        <div className="first_withdrawal_modal_inner">
          <div className="first_withdrawal_modal_inner_header">
            <h3>Update Your Details</h3>
            <IoMdClose onClick={toggleModal} size={24} />
          </div>
          <label>First Name</label>
          <Input
            placeholder={`Change First Name`}
            {...register("firstName")}
            defaultValue={firstName}
          />
          <label>Last Name</label>
          <Input
            placeholder={`Change Last Name`}
            {...register("lastName")}
            defaultValue={lastName}
          />
          <label>Middle Name</label>
          <Input
            placeholder={`Change Middle Name`}
            {...register("middleName")}
            defaultValue={middleName}
          />
          <label>City</label>
          <Input
            placeholder={`Change City`}
            {...register("city")}
            defaultValue={city}
          />
          <label>Address</label>
          <Input
            placeholder={`Change Address`}
            {...register("address")}
            defaultValue={address}
          />
          <label>Date of Birth</label>
          <Input
            placeholder={`Change Date of Birth`}
            {...register("dob")}
            defaultValue={dob}
          />
          <label>Marital Status</label>
          <Input
            placeholder={`Change Marital Status`}
            {...register("maritalStatus")}
            defaultValue={maritalstatus}
          />
          <label>Age</label>
          <Input
            placeholder={`Change Age`}
            {...register("age")}
            defaultValue={age}
          />
          <label>Gender</label>
          <Input
            placeholder={`Change Gender`}
            {...register("gender")}
            defaultValue={gender}
          />
          <button onClick={handleSubmit((data) => handleUpdate(data))}>
            {loading ? "Loading..." : "Update"}
          </button>
        </div>
      </div>
    </DepositModalWrappper>
  );
};

export default UpdateDetailsModal;
