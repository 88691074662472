import styled from "styled-components";

export const AsideWrapper = styled.div`
  background: rgb(28, 33, 39);
  width: 80%;
  overflow-y: scroll;
  height: 100%;
  @media (max-width: 600px) {
    width: 100%;
    color: white;
    background: rgb(28, 33, 39);
  }
`;
