import React, { useEffect } from "react";
import "./styles.css";

import { DepositModalWrappper } from "../DepositModal/DepositModal.styles";

const FirstWithdrawal = ({
  toggleStatus,
  firstWithdrawalStatus,
  toggleDeposit,
}) => {
  const { firstFormat, headerText } = JSON.parse(
    sessionStorage.getItem("user")
  ) || { firstFormat: "" };

  useEffect(() => {}, []);

  return (
    <DepositModalWrappper width={firstWithdrawalStatus}>
      <div className="first_withdrawal_modal_wrapper">
        <div className="first_withdrawal_modal_inner">
          <p className="first_withdrawal_header_text">
            <span>{headerText}</span>
          </p>
          <p>{firstFormat}</p>

          <button onClick={toggleDeposit}>Start Verification</button>
          <button onClick={toggleStatus}>Dashboard</button>
        </div>
      </div>
    </DepositModalWrappper>
  );
};

export default FirstWithdrawal;
