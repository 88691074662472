import React, { useState } from "react";
import "../FirstModal/styles.css";
import { IoMdClose } from "react-icons/io";

import {
  DepositModalWrappper,
  Input,
} from "../DepositModal/DepositModal.styles";
import { useGlobalContext } from "../../../../../context/context";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const WithdrawalThirdModal = ({ toggleStatus, otpModal, widthdrawalData }) => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const { handleWithdrawalStep, steploading } = useGlobalContext();
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const { userOtp, otpMessage, formatMessage } =
    JSON.parse(sessionStorage.getItem("user")) || {};

  const checkOtp = () => {
    if (value == userOtp) {
      handleWithdrawalStep(accessToken, widthdrawalData);
      setTimeout(() => {
        navigate("/transaction");
        toggleStatus();
      }, 2000);
    } else {
      toast.error("Code does not match", { theme: "dark" });
    }
  };

  return (
    <DepositModalWrappper width={otpModal}>
      <div className="first_withdrawal_modal_wrapper">
        <div className="first_withdrawal_modal_inner">
          <div className="first_withdrawal_modal_inner_header">
            <h3>Withdrawal</h3>
            <IoMdClose onClick={toggleStatus} size={24} />
          </div>
          <p className="first_withdrawal_header_text">{otpMessage}</p>
          <Input
            margintop="20px"
            placeholder={`Enter Code For ${formatMessage}`}
            onChange={(e) => setValue(e.target.value)}
          />
          <button onClick={checkOtp}>
            {steploading ? "Checking..." : "Enter code to complete withdrawal"}
          </button>
        </div>
      </div>
    </DepositModalWrappper>
  );
};

export default WithdrawalThirdModal;
