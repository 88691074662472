import styled from "styled-components";

export const Text = styled.h3`
  color: ${(props) =>
    props.activetab ? "white" : props.color ? props.color : ""};
  font-size: ${(props) => (props.size ? props.size : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  text-transform: ${(props) =>
    props.texttransform ? props.texttransform : ""};
  @media (max-width: 600px) {
    font-size: ${(props) => props.mobileSize};
    margin-bottom: ${(props) => props.mobilemarginbottom};
  }
`;
