import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

// Styles

import { Text } from "../../general.styles";
import { Btn } from "../../../pages/OverView/OverView.styles";

// Icons
import { IoClose } from "react-icons/io5";
import { ColorRing } from "react-loader-spinner";

import { checkSteps } from "../../../../../helper-methods";
import { useGlobalContext } from "../../../../../context/context";
import {
  BtnWrapper,
  DepositAside,
  DepositHeader,
  DepositLeft,
  DepositModalWrappper,
} from "../DepositModal/DepositModal.styles";
import WithdrawalThirdModal from "./WithdrawalThirdModal";

const Withdrawal = ({
  toggleStatus,
  withdrawalStatus,
  setWithdrawalStatus,
}) => {
  const [steps, setSteps] = useState(1);
  const [loading, setLoading] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const { _id, country, profit, bonus, transferStep, status } =
    JSON.parse(sessionStorage.getItem("user")) || {};
  const depositTotal = JSON.parse(sessionStorage.getItem("deposits"));
  const totalAmount = profit + depositTotal + bonus;
  const {
    paymentType,
    setWithdrawalData,
    widthdrawalData,
    depositHistory,
    toggleFirstWithdrawal,
  } = useGlobalContext();
  const statusText = "withdraw";

  const { register, handleSubmit } = useForm();

  const validateSteps = (data) => {
    data.userId = _id;
    if (totalAmount < data.amount) {
      toast.error("Insufficient Balance");
    }
    if (
      data.amount !== "" &&
      data.amount >= 100 &&
      totalAmount >= data.amount &&
      steps === 1
    ) {
      setLoading(true);
      setTimeout(() => {
        increamentStep(4);
        setLoading(false);
      }, 2000);
    } else if (data.amount === "" && steps === 1) {
      toast.error("Amount is required");
    } else if (data.amount < 100 && steps === 1) {
      toast.error(
        `Withdrawal amount must be greater than  ${country.symbol}100`
      );
    }
    if (
      data.bankName &&
      data.accountNumber &&
      data.accountType &&
      data.accountName !== ""
    ) {
      setLoading(true);
      setTimeout(() => {
        setWithdrawalData(data);
        increamentStep(5);
        setLoading(false);
      }, 2000);
    } else if (
      data.accountName === "" ||
      data.accountNumber === "" ||
      data.accountType === "" ||
      data.bankName === ""
    ) {
      toast.error("Please all input field must be valid");
    }
  };

  const increamentStep = (value) => {
    setSteps(value);

    if (value === 5) {
      if (status === "false") {
        toast.error(
          "User Withdrawal Amount is Inactive. Please reach out to customer care"
        );
        setSteps(4);
      } else if (transferStep === 1) {
        toggleFirstWithdrawal();
        setSteps(4);
      } else {
        setOtpModal(true);
      }
      setWithdrawalStatus(false);
    }
  };

  const toggleOtpModal = () => {
    setOtpModal(!otpModal);
    if (otpModal === true) {
      setSteps(4);
    }
  };

  return (
    <>
      <DepositModalWrappper width={withdrawalStatus}>
        <DepositLeft onClick={toggleStatus}></DepositLeft>
        <DepositAside width={withdrawalStatus}>
          <DepositHeader>
            <Text size="20px" fontWeight={500} color="white">
              Withdraw Money
            </Text>
            <IoClose
              size={28}
              color="rgb(160, 210, 254)"
              onClick={toggleStatus}
            />
          </DepositHeader>
          {checkSteps(
            steps,
            register,
            paymentType,
            statusText,
            withdrawalStatus
          )}
          <BtnWrapper>
            <Btn
              fontWeight={600}
              onClick={handleSubmit((data) => validateSteps(data))}
              width="100%"
              bgimage="linear-gradient(98.7deg,#5240f9 19.95%,#f3526d 89.51%)"
            >
              {loading ? (
                <ColorRing
                  visible={true}
                  height="50"
                  width="100"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
              ) : (paymentType === "bank" && steps === 3) ||
                (paymentType === "crypto" && steps === 3) ? (
                "I have completed deposit"
              ) : (
                "Procced"
              )}
            </Btn>
          </BtnWrapper>
        </DepositAside>
      </DepositModalWrappper>
      <WithdrawalThirdModal
        otpModal={otpModal}
        toggleStatus={toggleOtpModal}
        widthdrawalData={widthdrawalData}
      />
    </>
  );
};

export default Withdrawal;
