import React from "react";
import { useGlobalContext } from "../../../../../context/context";

// Styles
import { Text } from "../../general.styles";
import { StepThreeWrapper } from "./StepThree.styles";
import { MethodOne } from "../ModalStepTwo/ModalStepTwo.styles";
import { Btn, LabelBtn } from "../../../pages/OverView/OverView.styles";

// Icons
import { FaCheck } from "react-icons/fa";
import { Input } from "../DepositModal/DepositModal.styles";
import { Image } from "../../Navbar/Navbar.styles";

const StepThree = () => {
  const {
    validatePayments,
    paymentMethod,
    address,
    proof,
    setProof,
    proofImage,
    setProofImage,
  } = useGlobalContext();

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProofImage(e.target.files);
      setProof(URL.createObjectURL(e.target.files[0]));
    }
  };
  return (
    <StepThreeWrapper>
      <Text marginbottom="30px" color="white" size="19px" fontWeight={500}>
        Select your preferred wallet address
      </Text>
      {address.map((add, index) => (
        <MethodOne key={index}>
          <Text
            marginbottom="10px"
            color="white"
            size="18px"
            fontWeight={400}
            texttransform="capitalize"
          >
            {add.walletName}
          </Text>
          <Btn
            onClick={(e) => validatePayments(e, add.walletName)}
            id={add.walletName}
            size="15px"
            height="40px"
            width="90px"
            bg="rgb(37, 43, 49)"
          >
            {paymentMethod === add.walletName ? <FaCheck /> : "Select"}
          </Btn>
        </MethodOne>
      ))}
      <Text marginbottom="30px" color="white" size="19px" fontWeight={500}>
        Address:{" "}
        {address
          .filter((add) => add.walletName == paymentMethod)
          .map(({ address }) => address)}
      </Text>
      <Text marginbottom="30px" color="white">
        Please Click The Button Below to Upload Proof of Payment
      </Text>
      <Input
        display="none"
        type="file"
        id="proof-image"
        onChange={handleImageChange}
      />
      {proofImage.length > 0 && (
        <Image marginbottom="20px" src={proof} height={150} width={150} />
      )}
      <LabelBtn
        htmlFor="proof-image"
        fontWeight={600}
        bgimage="linear-gradient(98.7deg,#5240f9 19.95%,#f3526d 89.51%)"
        width="50%"
      >
        Proof
      </LabelBtn>
    </StepThreeWrapper>
  );
};

export default StepThree;
