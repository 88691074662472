import styled from "styled-components";

export const DepositModalWrappper = styled.div`
  backdrop-filter: blur(0.5rem);
  --webkit-backdrop-filter: blur(0.5rem);
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: ${(props) => (props.width ? "100%" : "0%")};
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: width 0.25s ease-in;
  z-index: 9999;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const DepositLeft = styled.div`
  width: 60%;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const DepositAside = styled.div`
  background-color: rgb(32, 37, 43);
  width: 40%;
  transform: translateX(${(props) => (props.width ? "0px" : "200%")});
  transition: transform 0.25s ease-in;
  border: 0.2px solid #454b51;
  border-top: none;
  position: relative;
  overflow-y: scroll;
  height: auto;
  padding-bottom: 15px;

  &:after {
    content: "";
    background-image: linear-gradient(98.7deg, #5240f9 19.95%, #f3526d 89.51%);
    width: 100%;
    height: 5px;
    position: absolute;
    top: 80px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const DepositHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(28, 33, 39);
  padding: 30px 20px;
`;

export const DepositForm = styled.form`
  padding: 30px;
  @media (max-width: 600px) {
    padding: 30px 20px;
    padding-bottom: 0px;
  }
`;

export const InputWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  background: rgb(28, 33, 39);
  border: 1px solid #454b51;
  margin-bottom: 30px;
  gap: 15px;
`;

export const Input = styled.input`
  padding: 20px;
  border-radius: 20px;
  background: rgb(28, 33, 39);
  border: 1px solid #454b51;
  margin-bottom: ${(props) => (props.margintop ? "0px" : "30px")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "0px")};
  width: 100%;
  font-size: 18px;
  outline: none;
  color: white;
  display: ${(props) => props.display};

  &::placeholder {
    color: rgb(165, 177, 189) !important;
    font-family: "Poppins", san-serif;
  }
`;

export const BtnWrapper = styled.div`
  margin-top: 30px;
  width: 90%;
  margin: auto;
`;
