import styled from "styled-components";

export const UserDashboardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;
  height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
