import React from "react";
import "../FirstModal/styles.css";

import { DepositModalWrappper } from "../DepositModal/DepositModal.styles";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../../../context/context";

const CheckWithdrawal = ({
  checkWithdrawalStatus,
  setCheckWithdrawalStatus,
}) => {
  const { firstName, transferStep } =
    JSON.parse(sessionStorage.getItem("user")) || {};
  const navigate = useNavigate();
  const { setToggleWith, withdrawHistory } = useGlobalContext();

  const navigateToTransactions = () => {
    navigate("/transaction");
    setCheckWithdrawalStatus(false);
    setToggleWith(false);
  };

  return (
    <DepositModalWrappper width={checkWithdrawalStatus}>
      <div className="first_withdrawal_modal_wrapper">
        <div className="first_withdrawal_modal_inner">
          {withdrawHistory?.length > 0 && transferStep === 10 ? (
            <p className="first_withdrawal_header_text">
              Hello{" "}
              <span style={{ textTransform: "capitalize" }}>{firstName}</span>{" "}
              Congratulations on your successful withdrawal! We are thrilled to
              confirm that your transaction has been completed, and the funds
              have been transferred as requested. Thank you for choosing our
              services, and we look forward to assisting you further in the
              future. If you have any questions or need assistance, please don't
              hesitate to contact us.
            </p>
          ) : (
            <p className="first_withdrawal_header_text">
              Hello{" "}
              <span style={{ textTransform: "capitalize" }}>{firstName}</span>{" "}
              Please Wait While Admin Process Your Withdrawal Or Reach Out To
              Customer Care Through Support Bot. Thank You!
            </p>
          )}

          <button id="transact_btn" onClick={navigateToTransactions}>
            Transactions
          </button>
        </div>
      </div>
    </DepositModalWrappper>
  );
};

export default CheckWithdrawal;
