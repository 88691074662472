import React, { useState } from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdVisibility } from "react-icons/md";
import { Button, Footer, Header } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Slide, toast } from "react-toastify";

const NewPassword = () => {
  const [visible, setVisible] = useState(true);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { baseUrl } = useGlobalContext();
  const { handleSubmit, register } = useForm();

  const toggleVisibility = () => setVisible(!visible);

  const handleResetPassword = (data) => {
    setloading(true);
    data.resetToken = id;

    axios
      .post(`${baseUrl}auth/reset`, data)
      .then((response) => {
        setloading(false);
        toast.success("Password Changed Successfully");
        if (response.status === 200) {
          setTimeout(() => {
            navigate("/auth/login");
          }, 2000);
        }
      })
      .catch((error) => {
        setloading(false);
        if (error?.response?.data === "Passwords do not match") {
          toast.error("Password Does not Matched", {
            transition: Slide,
            theme: "dark",
          });
        } else {
          toast.error("Token Expired", {
            transition: Slide,
            theme: "dark",
          });
        }
      });
  };

  return (
    <>
      <Header />
      <section className="register_container login_container forget_password">
        <h2 className="text_2">Enter New Password</h2>
        <form
          className="register_form"
          onSubmit={handleSubmit((data) => handleResetPassword(data))}
        >
          <div>
            <p className="text_text">New Password</p>
            <div className="register_password form_input">
              <input
                type={visible ? "text" : "password"}
                placeholder="Your Password"
                {...register("password", { required: "Password is required" })}
              />
              {visible ? (
                <AiOutlineEyeInvisible size={20} onClick={toggleVisibility} />
              ) : (
                <MdVisibility size={20} onClick={toggleVisibility} />
              )}
            </div>
          </div>
          <div>
            <p className="text_text">Confirm Password</p>
            <div className="register_password form_input">
              <input
                type={visible ? "text" : "password"}
                placeholder="Your Password"
                {...register("confirmpassword", {
                  required: "Password is required",
                })}
              />
              {visible ? (
                <AiOutlineEyeInvisible size={20} onClick={toggleVisibility} />
              ) : (
                <MdVisibility size={20} onClick={toggleVisibility} />
              )}
            </div>
          </div>
          <Button title={loading ? "Loading..." : "Reset"} action={() => ""} />
        </form>
      </section>
      <Footer />
    </>
  );
};

export default NewPassword;
