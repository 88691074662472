import styled from "styled-components";

export const DashboardHeaderWrapper = styled.div`
  padding: 20px 30px;
  border-bottom: 1px solid #535c6480;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 80%;
  height: 80px;
  background-color: rgb(28, 33, 39);
  z-index: 999;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const DashboardHeaderItemOne = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    cursor: pointer;
  }
`;
export const DashboardHeaderItemTwo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  svg {
    cursor: pointer;
  }
`;

export const DashboardProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  background-color: rgb(18, 23, 29);
  padding: 10px 15px;
  cursor: pointer;
`;
