import React from "react";
import "../../components/Modals/FirstModal/styles.css";

import { DepositModalWrappper } from "../../components/Modals/DepositModal/DepositModal.styles";
import { IoMdClose } from "react-icons/io";

const Refferal = ({ toggleModal, modal }) => {
  return (
    <DepositModalWrappper width={modal}>
      <div className="first_withdrawal_modal_wrapper">
        <div className="first_withdrawal_modal_inner">
          <div className="first_withdrawal_modal_inner_header">
            <h3>Referals</h3>
            <IoMdClose onClick={toggleModal} size={24} />
          </div>
          <p>
            We're thrilled to announce that the referal feature is coming soon
            to enhance your experience. Stay tuned for updates and get ready to
            explore a world of possibilities with us. We can't wait to share
            this exciting journey with you!
          </p>
          <button onClick={toggleModal} id="transact_btn">
            Close
          </button>
        </div>
      </div>
    </DepositModalWrappper>
  );
};

export default Refferal;
