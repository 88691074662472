import React from "react";
import "./Privacy.css";

// Components
import { Footer, Header } from "../../components";

const Privacy = () => {
  const name = "Capitalflowexchange";

  return (
    <section className="privacy_sect">
      <Header />
      <div className="privacy_container">
        <div className="privacy_container_item_one">
          <p className="text_text">1: Personal Information We Collect</p>
          <p className="text_text">2: How are we use personal Information</p>
          <p className="text_text">3. How We Share Your Personal Information</p>
          <p className="text_text">4: Rentention</p>
          <p className="text_text">5: Updates To this Privacy Policy</p>
          <p className="text_text">6: International Transfers</p>
        </div>
        <div className="privacy_container_item_two">
          <h3 className="home_header_text">Privacy Policy</h3>
          <p className="text_text">
            Welcome! Thanks for visiting {name}, a digital asset platform. Use
            of the words “{name},” “we,” “us,” or “our” refer to any or all
            {name} entities and their affiliates. This Privacy Policy explains
            what Personal Information (as defined below) we collect, why we
            collect it, how we use and disclose it, your choices, any rights you
            may have, and how you can contact us about our privacy practices. By
            continuing to use {name}, you agree to this Privacy Policy. This
            Privacy Policy does not apply to third-party websites, products, or
            services, even if we provide links to them or they link to us.
          </p>
          <p className="text_text">
            Your privacy matters to us so whether you are new to {name} or a
            long-time customer, please do take the time to get to know and
            familiarize yourself with our policies and practices. If you are a
            job applicant, please refer to our separate Applicant Privacy
            Statement. Feel free to print and keep a copy of this Privacy
            Policy, but please understand that we reserve the right to change
            any of our policies and practices at any time. But don’t worry, you
            can always find the latest version of this Privacy Policy here on
            this page.
          </p>
          <div className="privacy_items">
            <h3>NOTICE TO CALIFORNIA RESIDENTS</h3>
            <p className="text_text">
              Notice at Collection: We collect the categories of Personal
              Information listed below under “Personal Information We Collect.”
              We collect this information so that we can best serve you,
              including to fulfill your requests and to share offers that we
              think you may be interested in. A more detailed description of
              these purposes is described below under “How We Use Your Personal
              Information.” For more information on your privacy rights, please
              see “Information for California Residents” below.
            </p>
          </div>
          <div className="privacy_items">
            <h3>ENTITIES YOU ARE ENGAGING WITH</h3>
            <p className="text_text">
              This table below describes which {name} entity or entities you are
              engaging with, which determines the means and purposes of
              processing your Personal Information. If you are a customer based
              in the European Economic Area, please stop reading and go to this
              Privacy Policy, which will identify the {name} entities you are
              engaging with and how they use your Personal Information.
            </p>
          </div>
          <p className="text_text margin_top">
            Depending on what {name} products and services you use, we may
            provide you with an additional consumer privacy notice detailing how
            we will collect, use, and share your Personal Information for that
            product or service (each a “{name} Consumer Privacy Notice”). In the
            event of any conflict between this Privacy Policy and any {name}
            Consumer Privacy Notice, the {name} Consumer Privacy Notice will
            control. If you are a retail {name} customer or a {name} Credit Card
            customer, please find your {name} Consumer Privacy Notice here.
          </p>
          <div className="privacy_sections">
            <h3 className="home_header_text">
              1. Personal Information We Collect
            </h3>
          </div>
          <p className="text_text">
            <span>Overview:</span> Personal information typically means
            information that identifies or is reasonably capable of identifying
            an individual, directly or indirectly, and information that relates
            to, describes, is reasonably capable of being associated with or
            could reasonably be linked to an identified or reasonably
            identifiable individual. For the purposes of this Privacy Policy,
            only the definition of personal information from the applicable law
            of your legal residence will apply to you and be deemed your
            “Personal Information.”
          </p>
          <p className="text_text">
            <span>Identification Information:</span> such as name, email, phone
            number, postal address, and/or government-issued identity documents;
          </p>
          <p className="text_text">
            <span>Biometric Information:</span> such as scans of your face
            geometry extracted from identity documents;
          </p>
          <p className="text_text">
            <span>Financial Information:</span> such as bank account
            information, routing number;
          </p>
          <p className="text_text">
            <span>Commercial Information:</span> such as trading activity, order
            activity, deposits, withdrawals, account balances
          </p>
          <p className="text_text">
            <span>Sensitive Personal Information:</span> such as government
            identification numbers (which may include Social Security Number or
            equivalent, driver’s license number, passport number
          </p>
          <p className="text_text">
            <span>Additional Information:</span> as permitted by law or required
            to comply with legal obligations, which may include criminal records
            or alleged criminal activity, or information about any person or
            corporation with whom you have had, currently have, or may have a
            financial relationship.
          </p>
          <p className="text_text">
            Personal Information you provide during the registration process may
            be retained, even if your registration is left incomplete or
            abandoned.
          </p>
          <div className="privacy_sections">
            <h3 className="home_header_text">
              2. How We Use Your Personal Information
            </h3>
          </div>
          <p className="text_text">
            The Personal Information we collect and the practices described
            above are done in an effort to provide you with the best experience
            possible, protect you from risks related to improper use and fraud,
            and help us maintain and improve {name}. We may use your Personal
            Information to
          </p>
          <p className="text_text">
            <span>A. Provide you with our services.</span> We use your Personal
            Information to provide you with our services pursuant to the terms
            of our User Agreement. For example, in order to facilitate fiat
            transfers into and out of your account, we need to know your
            financial information.
          </p>
          <p className="text_text">
            <span>B. Comply with legal and regulatory requirements.</span> We
            process your Personal Information as required by applicable laws and
            regulations. For example, we have identity verification requirements
            to fulfill our obligations under the anti-money laundering laws of
            numerous jurisdictions and, as such, we may use an image of an
            identity document that you provide to help us verify that you are
            the true owner of the identity document and that the document does
            not show signs of fraud.
          </p>
          <p className="text_text">
            <span>C. Detect and prevent fraud.</span> We process your Personal
            Information to detect and prevent fraud on your account, which is
            especially important given the irreversible nature of cryptocurrency
            transactions.
          </p>
          <p className="text_text">
            <span>D. Market our products to you.</span> We may contact you with
            information about our products and services that we believe may be
            of interest to you. You may use tools that we provide to opt out of
            marketing communications from us at any time.
          </p>
          <p className="text_text">
            <span>E. With your consent.</span> We may use your Personal
            Information for additional purposes with your consent.
          </p>
          <div className="privacy_sections">
            <h3 className="home_header_text">
              3. How We Share Your Personal Information
            </h3>
          </div>
          <p className="text_text">
            We will not share your Personal Information with third parties,
            except as described below:
          </p>
          <p className="text_text">
            <span>A. Service Providers.</span> We may share your Personal
            Information with third-party service providers for business or
            commercial purposes. Your Personal Information may be shared so that
            they can provide us with services, including identity verification,
            fraud detection and prevention, credit verification, security threat
            detection, payment processing, customer support, data analytics,
            Information Technology, advertising, marketing, data processing,
            network infrastructure, storage, transaction monitoring, and tax
            reporting. We share your Personal Information with these service
            providers only so that they can provide us with services, and we
            prohibit our service providers from using or disclosing your
            Personal Information for any other purpose. Our third-party service
            providers are subject to strict confidentiality obligations.
          </p>
          <p className="text_text">
            <span>B. Professional Advisors.</span> We may share your Personal
            Information with our professional advisors, including legal,
            accounting, or other consulting services for purposes of audits or
            to comply with our legal obligations.
          </p>
          <p className="text_text">
            <span>C. Other business purposes.</span> We may share or disclose
            your Personal Information for other business purposes as permitted
            by law.
          </p>
          <p className="text_text">
            <span>D. Professional Advisors.</span> We may share your Personal
            Information with our professional advisors, including legal,
            accounting, or other consulting services for purposes of audits or
            to comply with our legal obligations.
          </p>
          <p className="text_text">
            If we decide to modify the purposes for which your Personal
            Information is collected, used, or shared, or our practices relating
            to your Personal Information, we will amend this Privacy Policy as
            described below.
          </p>
          <div className="privacy_sections">
            <h3 className="home_header_text">4. Rentention</h3>
            <p className="text_text">
              We retain your Personal Information for as long as is reasonably
              necessary to provide services to you, for our legitimate business
              purposes, and to comply with our legal and regulatory obligations.
              If you close your account with us, we will continue to retain your
              Personal Information as necessary to comply with our legal and
              regulatory obligations, including for fraud monitoring, detection
              and prevention; and for our tax, accounting, and financial
              reporting obligations.
            </p>
          </div>
          <div className="privacy_sections">
            <h3 className="home_header_text">
              5. Updates To this Privacy Policy
            </h3>
            <p className="text_text">
              12. Updates To this Privacy Policy We may change this Privacy
              Policy from time to time to reflect changes to our privacy
              practices, enhance user experience, or comply with relevant laws.
              The “Last updated” legend at the top of this Privacy Policy
              indicates when this Privacy Policy was last revised. Any changes
              are effective when we post the revised Privacy Policy. Your
              continued use of {name} after our posting of changes to this
              Privacy Policy means that you understand and agree to such
              changes.
            </p>
          </div>
          <div className="privacy_sections">
            <h3 className="home_header_text">6. International Transfers</h3>
            <p className="text_text">
              {name} is a global business. As a result, Personal Information may
              be stored and processed in any country where we have operations or
              where we engage service providers. We may transfer Personal
              Information that we maintain about you to recipients in countries
              other than the country in which the Personal Information was
              originally collected. Those other countries may have data
              protection or privacy rules that are different from those of your
              country. However, we will take measures to ensure that any such
              transfers comply with applicable data protection laws and that
              your Personal Information remains protected to the standards
              described in this Privacy Policy. In certain circumstances,
              courts, law enforcement agencies, regulatory agencies or security
              authorities in those other countries may be entitled to access
              your Personal Information.
            </p>
            <div className="privacy_items">
              <p className="text_text">
                If you are located in the European Economic Area (“EEA”), the UK
                or Switzerland, and are provided services by {name} Payments UK,
                Ltd and {name} Intergalactic UK, Ltd, we comply with applicable
                law to provide an adequate level of data protection for the
                transfer of your Personal Information to the US or other third
                countries. {name} enters into the appropriate data processing
                agreements and, if required, standard contractual clauses for
                the transfer of data which have been approved by the applicable
                data protection regulatory authority.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Privacy;
