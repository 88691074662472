import React from "react";
import { ModalThreeWrapper } from "../StepThree/StepThree.styles";
import StepThree from "../StepThree/StepThree";
import BankModal from "./BankModal";

const ModalThree = ({ paymentType }) => {
  return (
    <ModalThreeWrapper>
      {paymentType === "crypto" ? <StepThree /> : <BankModal />}
    </ModalThreeWrapper>
  );
};

export default ModalThree;
