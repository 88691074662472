import { CiSettings, CiWallet, CiLogin } from "react-icons/ci";
import { GrTransaction, GrOverview } from "react-icons/gr";
import buy from "./assets/buy-assets-icon.79a3281766eece62aaad2d107b461ab2.svg";
import sell from "./assets/sell-assets-icon.svg";
import convert from "./assets/swap-coin-icon.svg";
import bills from "./assets/pay-bill-icon.svg";
import nft from "./assets/nft-icon.svg";

export const NAV_LIST = [
  {
    sect: "dashboard",
    lists: [
      {
        name: "overview",
        showIcon: <GrOverview color="white" />,
        path: "/overview",
        hideIcon: <GrOverview color="rgb(165, 177, 189)" />,
      },
      {
        name: "wallet",
        showIcon: <CiWallet color="white" />,
        path: "/wallet",
        hideIcon: <CiWallet color="rgb(165, 177, 189)" />,
      },
      {
        name: "transactions",
        showIcon: <GrTransaction color="white" />,
        path: "/transaction",
        hideIcon: <GrTransaction color="rgb(165, 177, 189)" />,
      },
    ],
  },
  {
    sect: "account",
    lists: [
      {
        name: "settings",
        showIcon: <CiSettings color="white" />,
        path: "/settings",
        hideIcon: <CiSettings color="rgb(165, 177, 189)" />,
      },
      {
        name: "logout",
        hideIcon: <CiLogin color="rgb(165, 177, 189)" />,
      },
    ],
  },
];

export const optionsList = [
  { src: buy, text: "buy assets" },
  { src: sell, text: "sell assets" },
  { src: convert, text: "converts" },
  { src: bills, text: "pay bills" },
  { src: nft, text: "nft" },
];
