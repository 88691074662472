import styled from "styled-components";

export const OverViewWrapper = styled.section``;
export const OverViewSectOne = styled.div`
  display: flex;
  padding: 0 30px;
  margin-top: 80px;
  border-bottom: 1px solid #535c6480;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const OverViewSectOneAside = styled.div`
  width: ${(props) => (props.width ? props.width : "25%")};
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: ${(props) => props.margintop};
  padding-bottom: 20px;
`;
export const OverViewSectOneView = styled.div`
  border-right: 1px solid #535c6480;
  width: ${(props) => (props.width ? props.width : "75%")};
`;

export const OverViewSectOneViewSectionOne = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
export const OverViewSectOneViewHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => props.paddingy} 0;
  padding-right: 30px;
  justify-content: space-between;
`;

export const OverViewSectOneViewHeaderBtnSect = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;
export const Btn = styled.button`
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${(props) => props.bg};
  border-radius: 10px;
  border: none;
  font-size: ${(props) => (props.size ? props.size : "18px")};
  font-weight: ${(props) => props.fontWeight};
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  font-family: "Poppins", san-serif;
  justify-content: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-image: ${(props) => props.bgimage};
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
export const LabelBtn = styled.label`
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${(props) => props.bg};
  border-radius: 10px;
  border: none;
  font-size: ${(props) => (props.size ? props.size : "18px")};
  font-weight: ${(props) => props.fontWeight};
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  font-family: "Poppins", san-serif;
  justify-content: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-image: ${(props) => props.bgimage};
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const NotificationModal = styled.div`
  background-image: url(${(props) => props.bg});
  cursor: pointer;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

export const OverViewSectOneViewFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-right: 30px;
`;

export const QuickItem = styled.div`
  background-color: rgb(32, 37, 43);
  border-color: rgb(38, 41, 50);
  border-radius: 1rem;
  cursor: pointer;
  padding: 16px;
  width: 20%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s;
  }
`;
export const QuickItemIconWrapper = styled.div``;
export const OverViewSectTwo = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;
