import React from "react";
import { AiOutlineCustomerService } from "react-icons/ai";
import { CiLogin, CiUser } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import logo from "../../../../assets/elite.png";

const MiniHeader = () => {
  const navigate = useNavigate();

  const logOut = () => {
    sessionStorage.removeItem("accessToken");
    navigate("/auth/login");
  };
  const navigateToSettings = () => navigate("/settings");
  return (
    <section className="mobile_header">
      <div className="flag_wrapper">
        <img src={logo} alt="" />
      </div>
      <div className="support_sect">
        <a
          style={{ display: "flex", alignItems: "center" }}
          href="mailto:support@capitalflowxchange.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineCustomerService size={24} color="white" />
        </a>
        <CiUser size={24} color="white" onClick={navigateToSettings} />
        <CiLogin size={24} color="white" onClick={logOut} />
      </div>
    </section>
  );
};

export default MiniHeader;
