import React from "react";

// Styles
import { EmptyTransactionContainer } from "../pages/Transactions/Transaction.styles";
import { Image } from "./Navbar/Navbar.styles";
import { Text } from "./general.styles";

// Images
import emyty from "../../../assets/not-found-icon.svg";

const EmptyWrapper = () => {
  return (
    <EmptyTransactionContainer>
      <Image src={emyty} width={160} height={160} alt="empty" />
      <Text
        margintop="20px"
        color="rgb(165, 177, 189)"
        fontWeight={400}
        size={16}
      >
        No transactions yet
      </Text>
    </EmptyTransactionContainer>
  );
};

export default EmptyWrapper;
