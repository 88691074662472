import React from "react";
import { UserDashboardWrapper } from "./UserDashboard.styles";
import { Aside, Navbar } from "../../components";

// Styles

const UserDashbord = () => {
  return (
    <UserDashboardWrapper>
      <Navbar />
      <Aside />
    </UserDashboardWrapper>
  );
};

export default UserDashbord;
