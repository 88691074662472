import styled from "styled-components";

export const TransactionWrapper = styled.section`
  height: 100%;
`;
export const TransactionBody = styled.div`
  padding: 30px;
  margin-top: 80px;
  height: 100%;
  overflow: scroll;
  @media (max-width: 600px) {
    padding: 20px;
    margin-top: 20px;
    padding-bottom: 50px;
  }
`;

export const EmptyTransactionContainer = styled.div`
  border: 1px solid rgb(50, 56, 63);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
