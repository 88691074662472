import React from "react";

// Styles
import { StepThreeWrapper } from "../StepThree/StepThree.styles";
import { Text } from "../../general.styles";
import { MethodOne } from "../ModalStepTwo/ModalStepTwo.styles";
import { LabelBtn } from "../../../pages/OverView/OverView.styles";
import { Input } from "./DepositModal.styles";
import { Image } from "../../Navbar/Navbar.styles";
import { useGlobalContext } from "../../../../../context/context";

const BankModal = () => {
  const { proof, setProof, proofImage, setProofImage, bankDetails } =
    useGlobalContext();

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProofImage(e.target.files);
      setProof(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <StepThreeWrapper>
      {bankDetails === undefined ? (
        <Text
          marginbottom="30px"
          color="white"
          size="19px"
          fontWeight={500}
          mobileSize="17px"
        >
          Bank Details Unavailable
        </Text>
      ) : (
        <>
          <Text
            marginbottom="30px"
            color="white"
            size="19px"
            fontWeight={500}
            mobileSize="17px"
          >
            Bank Details
          </Text>
          <MethodOne>
            <Text
              marginbottom="10px"
              color="white"
              size="18px"
              fontWeight={400}
              mobileSize="15px"
            >
              Account Name
            </Text>
            <Text
              marginbottom="10px"
              color="white"
              size="18px"
              fontWeight={400}
              mobileSize="15px"
            >
              {bankDetails?.accountName}
            </Text>
          </MethodOne>
          <MethodOne>
            <Text
              marginbottom="10px"
              color="white"
              size="18px"
              fontWeight={400}
              mobileSize="15px"
            >
              Bank Name
            </Text>
            <Text
              marginbottom="10px"
              color="white"
              size="18px"
              fontWeight={400}
              mobileSize="15px"
            >
              {bankDetails?.bankName}
            </Text>
          </MethodOne>
          <MethodOne>
            <Text
              marginbottom="10px"
              color="white"
              size="18px"
              fontWeight={400}
              mobileSize="15px"
            >
              Account Number
            </Text>
            <Text
              marginbottom="10px"
              color="white"
              size="18px"
              fontWeight={400}
              mobileSize="15px"
            >
              {bankDetails?.accountNumber}
            </Text>
          </MethodOne>
          <MethodOne>
            <Text
              marginbottom="10px"
              color="white"
              size="18px"
              fontWeight={400}
              mobileSize="15px"
            >
              Account Type
            </Text>
            <Text
              marginbottom="10px"
              color="white"
              size="18px"
              fontWeight={400}
              mobileSize="15px"
            >
              {bankDetails?.bankType}
            </Text>
          </MethodOne>
          <Text marginbottom="30px" color="white" mobileSize="17px">
            Please Click The Button Below to Upload Proof of Payment
          </Text>
        </>
      )}
      <Input
        display="none"
        type="file"
        id="proof-image"
        onChange={handleImageChange}
      />
      {proofImage.length > 0 && (
        <Image marginbottom="20px" src={proof} height={150} width={150} />
      )}
      {bankDetails !== undefined && (
        <LabelBtn
          htmlFor="proof-image"
          fontWeight={600}
          bgimage="linear-gradient(98.7deg,#5240f9 19.95%,#f3526d 89.51%)"
          width="50%"
        >
          Proof
        </LabelBtn>
      )}
    </StepThreeWrapper>
  );
};

export default BankModal;
