import React from "react";

// Styles
import {
  DepositForm,
  Input,
  InputWrap,
} from "../DepositModal/DepositModal.styles";
import { Text } from "../../general.styles";
import { Image } from "../../Navbar/Navbar.styles";

const ModalStepOne = ({ register, statusText }) => {
  const { country } = JSON.parse(sessionStorage.getItem("user")) || {};
  const { name, flag, symbol } = country || {};
  return (
    <DepositForm>
      <Text
        marginbottom="10px"
        color="rgb(165, 177, 189)"
        size="16px"
        fontWeight={400}
      >
        Currency to {statusText}
      </Text>
      <InputWrap>
        <Image src={flag} width={20} height={20} radius="5px" />
        <Text color="white" size="18px" fontWeight={600}>
          {name} {symbol}
        </Text>
      </InputWrap>
      <Text
        marginbottom="10px"
        color="rgb(165, 177, 189)"
        size="16px"
        fontWeight={400}
      >
        Enter amount to {statusText}
      </Text>
      <Input placeholder="Enter Amount" {...register("amount")} />
    </DepositForm>
  );
};

export default ModalStepOne;
