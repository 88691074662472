import React, { useEffect, useState } from "react";
import "./styles.css";
import { DashboardHeader, MiniHeader } from "../../components";
import { useGlobalContext } from "../../../../context/context";
import UpdateDetailsModal from "./UpdateDetailsModal";
import BasicInfo from "./components/BasicInfo";
import NextOfKin from "./NextOfKin";
import Refferal from "./Refferal";

const Settings = () => {
  const [updatedDetailsModal, setUpdatedDetailsModal] = useState(false);
  const [referalModal, setReferalModal] = useState(false);
  const [nextOfKinModal, setNextOfKinModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const { getUser } = useGlobalContext();
  const { _id } = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));

  const toggleModal = () => setUpdatedDetailsModal(!updatedDetailsModal);
  const toggleNextOfKin = () => setNextOfKinModal(!nextOfKinModal);
  const toggleReferal = () => setReferalModal(!referalModal);

  const handlecurrentTab = (e) => {
    const content = e.target.textContent;
    if (content === "Next of Kin") {
      toggleNextOfKin();
    } else if (content === "Refferals") {
      toggleReferal();
    } else {
      setCurrentTab(1);
    }
  };

  useEffect(() => {
    getUser(accessToken, _id);
  }, []);

  return (
    <>
      <section className="settings_wrapper">
        <DashboardHeader pathname={"Settings"} />
        <MiniHeader />
        <div className="settings_container">
          <div className="settings_tab">
            <div className="settings_btn_wrapper setting_btn_wrapper">
              <button
                onClick={handlecurrentTab}
                className={`${currentTab === 1 && "active_button"}`}
              >
                Basic information
              </button>
              <button
                className={`${currentTab === 2 && "active_button"}`}
                onClick={handlecurrentTab}
              >
                Next of Kin
              </button>
              <button
                className={`${currentTab === 3 && "active_button"}`}
                onClick={handlecurrentTab}
              >
                Refferals
              </button>
            </div>
          </div>

          <BasicInfo toggleModal={toggleModal} />
        </div>
      </section>
      <UpdateDetailsModal
        modal={updatedDetailsModal}
        toggleModal={toggleModal}
        setTab={setCurrentTab}
      />
      <NextOfKin modal={nextOfKinModal} toggleModal={toggleNextOfKin} />
      <Refferal modal={referalModal} toggleModal={toggleReferal} />
    </>
  );
};

export default Settings;
