import React from "react";

// Styles
import {
  MethodsWrapper,
  MethodsContainer,
  MethodOne,
  TextContainer,
} from "./ModalStepTwo.styles";
import { Text } from "../../general.styles";
import { Image } from "../../Navbar/Navbar.styles";
import { Btn } from "../../../pages/OverView/OverView.styles";

// Images
import bank from "../../../../../assets/bank-route-icon.svg";
import crypto from "../../../../../assets/crypto.jpeg";

// Icons
import { FaCheck } from "react-icons/fa";
import { useGlobalContext } from "../../../../../context/context";

const ModalStepTwo = () => {
  const { setPaymentType, paymentType } = useGlobalContext();

  const validateMethods = (e) => {
    if (e.target.id === "1") {
      setPaymentType("bank");
    } else if (e.target.id === "2") {
      setPaymentType("crypto");
    }
  };

  return (
    <MethodsWrapper>
      <Text
        marginbottom="30px"
        color="white"
        size="19px"
        fontWeight={500}
        mobileSize="15px"
      >
        Select your preferred deposit method
      </Text>
      <MethodsContainer>
        <MethodOne>
          <Image
            src={bank}
            alt="bank payment method"
            width={50}
            height={50}
            radius="50px"
            mobileWidth="35px"
          />
          <TextContainer>
            <Text
              marginbottom="10px"
              mobilemarginbottom="5px"
              color="white"
              size="18px"
              fontWeight={400}
              mobileSize="15px"
            >
              Bank transfer
            </Text>
            <Text
              mobileSize="13px"
              color="rgb(165, 177, 189)"
              size="16px"
              fontWeight={400}
            >
              Deposit with ease using bank transfers
            </Text>
          </TextContainer>
          <Btn
            onClick={validateMethods}
            id="1"
            size="15px"
            height="40px"
            width="90px"
            bg="rgb(37, 43, 49)"
          >
            {paymentType === "bank" ? <FaCheck /> : "Select"}
          </Btn>
        </MethodOne>
        <MethodOne>
          <Image
            src={crypto}
            alt="crypto payment method"
            width={50}
            height={50}
            radius="50px"
            mobileWidth="35px"
          />
          <TextContainer>
            <Text
              marginbottom="10px"
              color="white"
              size="18px"
              fontWeight={400}
              mobileSize="15px"
              mobilemarginbottom="5px"
            >
              Deposit with crypto
            </Text>
            <Text
              mobileSize="13px"
              color="rgb(165, 177, 189)"
              size="16px"
              fontWeight={400}
            >
              Deposit with the ease of cryptocurrencies
            </Text>
          </TextContainer>
          <Btn
            onClick={validateMethods}
            id="2"
            size="15px"
            height="40px"
            width="90px"
            bg="rgb(37, 43, 49)"
          >
            {paymentType === "crypto" ? <FaCheck /> : "Select"}
          </Btn>
        </MethodOne>
      </MethodsContainer>
    </MethodsWrapper>
  );
};

export default ModalStepTwo;
