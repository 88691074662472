import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavbarWrapper = styled.nav`
  background-color: rgb(36, 39, 58);
  width: 20%;
  padding: 20px;
  height: 100vh;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const NavbarLogoSection = styled.div`
  margin-bottom: 40px;
`;
export const Image = styled.img`
  margin-bottom: ${(props) => props.marginbottom};
  height: revert-layer;
  width: revert-layer;
  border-radius: ${(props) => props.radius};
  @media (max-width: 600px) {
    height: ${(props) => props.mobileWidth};
    width: ${(props) => props.mobileWidth};
  }
`;

export const NavListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavListItemsContainer = styled(Link)`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  background-image: linear-gradient(
    ${(props) => props.activetab && "98.7deg, #5240f9 19.95%, #f3526d 89.51%"}
  );
  border-radius: 5px;
  padding: 10px;
  transition: background 300ms ease-in;
  svg {
    font-size: 25px;
  }
`;
