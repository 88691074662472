import React, { useState } from "react";
import "./styles.css";

import { MdOutlineVisibility } from "react-icons/md";
import { MdOutlineVisibilityOff } from "react-icons/md";

import deposit from "../../../../assets/wallet-deposit.svg";
import Withdraw from "../../../../assets/wallet-withdraw.svg";
import transfer from "../../../../assets/wallet-transfer.svg";
import {
  AdvancedRealTimeChart,
  CryptoCurrencyMarket,
} from "react-ts-tradingview-widgets";
import { useGlobalContext } from "../../../../context/context";
import { MiniHeader } from "..";

const ModalbileHeader = ({
  total,
  firstName,
  lastName,
  toggleBuy,
  toggleSell,
}) => {
  const [priceVisible, setPriceVisible] = useState(false);

  const { toggleStatus, toggleWithdrawal, moneyFormatter } = useGlobalContext();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const { country } = user;

  const toggleVisible = () => {
    setPriceVisible(!priceVisible);
  };

  return (
    <>
      <MiniHeader />
      <section className="welcome_sect">
        <h3>
          Welcome, {firstName} {lastName}
        </h3>
        <p>
          Here is what you need to do to get started with capital flow exchange
        </p>
      </section>
      <section className="balance_sect_amount">
        <div className="balance_sect">
          <p>Your Balance</p>
          {priceVisible ? (
            <MdOutlineVisibility onClick={toggleVisible} />
          ) : (
            <MdOutlineVisibilityOff onClick={toggleVisible} />
          )}
        </div>
        {!priceVisible ? (
          <h3>
            {country?.symbol}
            {moneyFormatter.format(total)}
          </h3>
        ) : (
          <h3>....</h3>
        )}
      </section>
      <section className="radius_sect">
        <div>
          <img src={deposit} alt="" onClick={toggleStatus} />
          <p>Deposit</p>
        </div>
        <div>
          <img src={Withdraw} alt="" onClick={toggleWithdrawal} />
          <p>Withdr...</p>
        </div>
        <div>
          <img src={transfer} alt="" onClick={toggleBuy} />
          <p>Buy</p>
        </div>
        <div>
          <img src={transfer} alt="" onClick={toggleSell} />
          <p>Sell</p>
        </div>
      </section>
      <div className="signal_sect">
        <AdvancedRealTimeChart theme="dark" width={"100%"} />
        <CryptoCurrencyMarket colorTheme="dark" width={"100%"} />
      </div>
    </>
  );
};

export default ModalbileHeader;
